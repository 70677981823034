import { passwordStrength } from 'check-password-strength'

export function checkPasswordStrength(password: string) {
  if (password === '') return 1;

  switch (passwordStrength(password).value) {
    case 'Too weak':
    case 'Weak':
      return 2;
    case 'Medium':
      return 3;
    case 'Strong':
      return 4;
  }
}

export function customInnChecker(value: string) {
  return customCodeChecker(value, [10, 12], 'ИНН')
}

export function customKppChecker(value: string) {
  return customCodeChecker(value, [9], 'КПП')
}

export function customOGRNChecker(value: string) {
  return customCodeChecker(value, [13], 'ОГРН')
}

export function customCodeChecker(value: string, valueLength: number[], label: string) {
  const spaces = /\s/;
  const numbers = /^\d+$/;

  if (spaces.test(value)) return `${ label } не должен содержать пробелы`
  if (!numbers.test(value)) return `${ label } должен содержать только цифры`
  if (!valueLength.includes(value.length)) return `${ label } должен содержать ${ valueLength.length === 1 ? valueLength[0] : valueLength.join(' или ') } цифр`
  return ''
}

export function customNumberChecker(value: string) {
  const spaces = /\s/;
  const numbers = /^\d+$/;

  if (spaces.test(value)) return 'Введенное значение не должно содержать пробелы'
  if (!numbers.test(value)) return 'Введенное значение должно содержать только цифры'
  return ''
}
